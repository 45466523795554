import validate from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import terms_45of_45service_45middleware_45global from "/codebuild/output/src2687641029/src/eec-webapp/middleware/termsOfServiceMiddleware.global.ts";
import manifest_45route_45rule from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  terms_45of_45service_45middleware_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/codebuild/output/src2687641029/src/eec-webapp/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}