export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (err, vm, info) => {
    console.error('Global Vue Component Error:', err, info);

    // Handle specific error logic
    if (err && typeof err === 'object') {
      const errorStatus = err?.status || err?.response?.status; // Support Axios-like errors or custom status

      if (errorStatus >= 400 && errorStatus < 500) {
        // Ignore client-side errors (e.g., 400-level errors)
        console.warn('Client-side error occurred:', errorStatus, err);
      } else {
        // Handle all other errors (e.g., >=500 or undefined status)
        console.log('Unexpected or critical error:', errorStatus, err);
        navigateTo('/500');
      }
    } else {
      // Handle errors without a clear status (e.g., runtime errors)
      console.log('Unstructured or unknown error type:', err);
      navigateTo('/500');
    }
  };
});
