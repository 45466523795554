import { useHelperStore } from '~/stores/helpers';
import { useMemberPostStore } from '~/stores/memberPosts';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import _ from 'lodash';
import { useUserStore } from '~/stores/auth';
import { DateTime } from 'luxon';

type AddDraft = {
  eventType: 'ADD_DRAFT';
  id: string;
  version: string;
  userId: number;
};

const contextUser = (userId: number) => {
  const piniaUser = useUserStore();
  const ctxId = piniaUser.user as unknown as {
    summary: { basicUserInfo: { userId: number } };
  };
  return ctxId.summary.basicUserInfo.userId === userId;
};

const calculateDaysUntilExpiration = (expirationMillis: number): number => {
  const expirationDate = DateTime.fromMillis(expirationMillis);

  // Calculate the difference in days
  return expirationDate.toRelative();
};

async function fetchPostDetailWithRetry(postId, retries = 5, delay = 1500) {
  const piniaPosts = useMemberPostStore();

  for (let attempt = 1; attempt <= retries; attempt++) {
    console.log(`Attempt ${attempt} to fetch post details`);

    // Fetch post details
    await piniaPosts.getPostDetailCreation(postId);

    // Check if post details meet your condition
    if (piniaPosts.latestPostDetail?.postDetail?.postId === postId) {
      console.log(
        'Post details fetched successfully and data is updated in the store.'
      );
      return piniaPosts.latestPostDetail;
    }

    // Wait before retrying
    if (attempt < retries) {
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw new Error(
    'Failed to fetch updated post details after multiple retries.'
  );
}

const fetchLatestPostDetail = _.debounce((eventType) => {
  const emitter = useNuxtApp().$emitter;
  const piniaPosts = useMemberPostStore();
  const time =
    piniaPosts.latestPostDetail?.postDetail?.expirationDateTimeMillis;

  const piniaHelper = useHelperStore();
  const { status } = piniaHelper.latestResponseStatus as { status: number };

  const message = `Draft post successfully created and will expire ${calculateDaysUntilExpiration(
    time
  )}`;

  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    message,
    eventType,
    deviceWidth: (window?.innerWidth ?? 0) as number,
  });
}, 1000);

export async function handleAddDraft(
  ...[_channel, data]: PayloadDataType<AddDraft>
) {
  DEBUG && console.log('handlePusherMessages', data);
  if (contextUser(data.userId)) {
    const emitter = useNuxtApp().$emitter;
    emitter.$emit('provide-version', data.version);

    try {
      await fetchPostDetailWithRetry(data.id);
    } catch (error) {
      console.error('Error fetching post detail:', error.message);
    }

     fetchLatestPostDetail(data.eventType);
  }
}
