import { defineStore } from 'pinia';
import MemberDetail from '../models/MemberDetail';
import { usePinia } from '#imports';
import { handleErrorAndNotificationHandler } from '~/util/errorNotificationHandler';

export const useChannelsStore = defineStore('channels', {
  state: () => ({
    channels: [], //rename into items
    activeChannel: {
      channelId: '',
    },
    syncing: false,
    activeChannelMemberPostsFetched: false,
    allChannelMemberPostsFetched: false,
    paginator: [],
    stateInitiated: false,
    channelTeams: {},
    studyTeams: {},
    channelMembers: {},
    channelTeamMembers: [],
    memberDetail: {},
    keyContacts: [],
    postDetailVersion: {},
    channelSummary: [],
    teamType: null,
    teamEdit: {},
    oversightAction: '',
    teamCreated: {},
    teamInfo: {},
    inviteMessage: '',
    channelMembersCached: {},
    otherTeamName: '',
    updatedOtherteam: '',
    addressAction: '',
    invitedMemberDetail: {},
    memberRoles: [],
    channelRoles: {},
    error: {},
    memberSearchTerm: '',
  }),
  actions: {
    async syncChannels(channels) {
      try {
        const pinia = usePinia();
        if (!channels) {
          const resp = await pinia.$axios.$get(pinia.$API.app.initMember);
          return (this.channels = resp.channelSummaries);
        }
        return (this.channels = channels);
      } catch (error) {
        handleErrorAndNotificationHandler('Error syncing channels:', error);
        return null;
      }
    },
    setActiveChannel(payload) {
      if (
        this.activeChannel &&
        this.activeChannel.channelId !== payload.channelId
      ) {
        this.activeChannelMemberPostsFetched = false;
      }
      this.activeChannel = payload;
    },
    removeChannel(channelId) {
      this.channels = this.channels.filter(
        (c) => c.channelId !== parseInt(channelId)
      );
    },
    updateChannelsCollection(channel) {
      const index = this.channels.findIndex(
        (c) => c.channelId === channel.channelId
      );

      if (index !== -1) {
        this.channels[index] = channel;
      } else {
        this.channels.push(channel);
      }
    },
    async getChannelSummary({ countryId, channelId, availableRolesCategory }) {
      try {
        const Query = usePinia().$Query;
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.channelSummary(
            channelId,
            new Query({
              countryId,
              availableRolesCategory,
            }).getQueryParameters()
          )
        );

        let key = channelId;
        if (countryId) {
          key += `>${countryId}`;
        }
        this.channelSummary = {
          ...this.channelSummary,
          [key]: resp,
        };
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in fetching channel summary',
          error
        );
      }
    },
    async handleSubscription(channelId) {
      if (
        this.activeChannel.userRelatedData.userSubscribedToNotifications ===
        false
      ) {
        try {
          await usePinia().$axios.$post(
            usePinia().$API.user.subscribeToChannel(channelId)
          );
          this.activeChannel.userSubscribedToNotifications =
            !this.activeChannel.userSubscribedToNotifications;
        } catch (error) {
          handleErrorAndNotificationHandler(
            'Error in handle subscription',
            error
          );
        }
        return;
      }
      try {
        await usePinia().$axios.$delete(
          usePinia().$API.user.subscribeToChannel(channelId)
        );
        this.activeChannel.userSubscribedToNotifications =
          !this.activeChannel.userSubscribedToNotifications;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in handling subscription',
          error
        );
      }
    },
    async cachedChannelMembers({
      pageSize,
      sort,
      memberStatusType,
      channelId,
      search,
      page,
    }) {
      try {
        const Query = usePinia().$Query;
        const resp = await usePinia().$axios.$post(
          usePinia().$API.channels.channelMembers(
            channelId,
            new Query({
              pageSize,
              page,
              sort,
              memberStatusType,
              search,
            }).getQueryParameters()
          ),
          []
        );

        let key = '';
        switch (memberStatusType) {
          case 'CURRENT':
          case 'INVITED':
          case 'PAST':
          case 'ALL':
            key = `${channelId}>${memberStatusType}`;
            this.channelMembersCached = {
              ...this.channelMembersCached,
              [key]: resp,
            };
            break;
          default:
            break;
        }
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error caching channel members:',
          error
        );
      }
    },
    searchMembersTerm(payload) {
      this.memberSearchTerm = payload;
    },
    mutateChannelMemberCached(payload) {
      const channelId = payload.channelMemberId.split('-')[0];
      const keyCurrent = `${channelId}>CURRENT`;
      const keyInvited = `${channelId}>INVITED`;
      const keyFormer = `${channelId}>FORMER`;

      if (
        !payload.membershipsByStatus.INVITED &&
        this.channelMembersCached[keyInvited]
      ) {
        this.channelMembersCached[keyInvited].items = this.channelMembersCached[
          keyInvited
        ].items.filter(
          (obj) => obj.channelMemberId !== payload.channelMemberId
        );
      }
      if (
        !payload.membershipsByStatus.CURRENT &&
        this.channelMembersCached[keyCurrent]
      ) {
        this.channelMembersCached[keyCurrent].items = this.channelMembersCached[
          keyCurrent
        ].items.filter(
          (obj) => obj.channelMemberId !== payload.channelMemberId
        );
        this.channelMembersCached[keyCurrent].totalCount--;
      }
      if (
        payload.membershipsByStatus.INVITED &&
        this.channelMembersCached[keyInvited]
      ) {
        const findIndex = this.channelMembersCached[keyInvited].items.findIndex(
          (obj) => obj.channelMemberId === payload.channelMemberId
        );

        if (findIndex !== -1) {
          const newObj = {
            ...payload,
            memberships: [...payload.membershipsByStatus.INVITED],
            roleNames: [...payload.rolesByStatus.INVITED.map((i) => i.title)],
            channelMemberDetailUrl: `/o/emsere-openapi/v1.0.0/channels/${channelId}/members/${payload.channelMemberId}`,
          };

          this.channelMembersCached[keyInvited].items[findIndex] = newObj;
        }
      }
      if (
        payload.membershipsByStatus.CURRENT &&
        this.channelMembersCached[keyCurrent]
      ) {
        const findIndex = this.channelMembersCached[keyCurrent].items.findIndex(
          (obj) => obj.channelMemberId === payload.channelMemberId
        );
        const newObj = {
          ...payload,
          memberships: [...payload.membershipsByStatus.CURRENT],
          roleNames: [...payload.rolesByStatus.CURRENT.map((i) => i.title)],
          channelMemberDetailUrl: `/o/emsere-openapi/v1.0.0/channels/${channelId}/members/${payload.channelMemberId}`,
        };
        if (findIndex !== -1) {
          this.channelMembersCached[keyCurrent].items[findIndex] = newObj;
        } else {
          this.channelMembersCached[keyCurrent].items.push(newObj);
          this.channelMembersCached[keyCurrent].totalCount++;
        }
      }
      if (
        payload.membershipsByStatus.FORMER &&
        this.channelMembersCached[keyFormer]
      ) {
        const newItems = this.channelMembersCached[keyFormer].items.map(
          (item) => {
            if (item.channelMemberId === payload.channelMemberId) {
              return {
                ...item,
                memberships: [payload.membershipsByStatus.FORMER],
                roleNames: [
                  ...payload.rolesByStatus.FORMER.map((i) => i.title),
                ],
                channelMemberDetailUrl: `/o/emsere-openapi/v1.0.0/channels/${channelId}/members/${payload.channelMemberId}`,
              };
            }
            return item;
          }
        );
        this.channelMembersCached[keyFormer].items = newItems;
      }
    },
    async setChannelTeamMembers({
      pageSize,
      sort,
      memberStatusType,
      channelId,
      search,
      page,
      teamId,
    }) {
      try {
        const Query = usePinia().$Query;
        const resp = await usePinia().$axios.$post(
          usePinia().$API.channels.channelTeamMembers(
            channelId,
            teamId,
            new Query({
              pageSize,
              page,
              sort,
              memberStatusType,
              search,
            }).getQueryParameters()
          ),
          []
        );
        let key = '';
        switch (memberStatusType) {
          case 'CURRENT':
          case 'INVITED':
          case 'PAST':
          case 'ALL':
            key = `${channelId}>${teamId}>${memberStatusType}`;
            this.channelTeamMembers = {
              ...this.channelTeamMembers,
              [key]: resp,
            };
            break;
          default:
            break;
        }
        // this.channelTeamMembers = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in set channel team members',
          error
        );
      }
    },
    async channelMemberDetail({ channelId, memberId }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.channelMemberDetail(channelId, memberId)
        );
        const key = (channelId += `>${memberId}`);
        this.memberDetail = {
          ...this.memberDetail,
          [key]: new MemberDetail(resp),
        };
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in channel member detail',
          error
        );
      }
    },
    async getKeyContacts({ channelId, page, pageSize }) {
      try {
        const Query = usePinia().$Query;
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.channelKeyContacts(
            channelId,
            new Query({
              page,
              pageSize,
            }).getQueryParameters()
          )
        );
        const key = channelId;
        this.keyContacts = {
          ...this.keyContacts,
          [key]: resp,
        };
      } catch (error) {
        handleErrorAndNotificationHandler('Error in get key contacts', error);
      }
    },
    /**
     * Sends an invitation to the user
     * @param {*} param0
     * @param { MemberInvite } memberInvite
     */
    async inviteNewMembers(memberInvite) {
      try {
        await memberInvite.save();
      } catch (error) {
        if (error.response) {
          // Request made and server responded
          handleErrorAndNotificationHandler(
            'Error in invite new members',
            error
          );
          return error.response;
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Error in invite new members', error.request);
          return error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      }
    },
    async resendInvitation({
      teamId,
      channelId,
      inviteId,
      updatedEmailAddress,
    }) {
      try {
        const Query = usePinia().$Query;
        await usePinia().$axios.$patch(
          usePinia().$API.channels.resendInvitation(
            channelId,
            teamId,
            inviteId,
            new Query({
              updatedEmailAddress,
            }).getQueryParameters()
          )
        );
      } catch (error) {
        handleErrorAndNotificationHandler('Error resending invitation:', error);
      }
    },
    async updateInvitedMemberRole({
      teamId,
      channelId,
      inviteId,
      roleIds,
      sendEmailInvitation,
      email,
      invitedUserFirstName,
      invitedUserLastName,
    }) {
      try {
        await usePinia().$axios.$put(
          usePinia().$API.channels.updateInvitedMemberRole(
            channelId,
            teamId,
            inviteId
          ),
          {
            sendEmailInvitation,
            roleIds,
            email,
            invitedUserFirstName,
            invitedUserLastName,
          }
        );
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in update invited member role',
          error
        );
      }
    },
    inviteMemberMessage(inviteMessage) {
      this.inviteMessage = inviteMessage;
    },
    async removeMemberFromChannel({ channelId, memberId }) {
      try {
        await usePinia().$axios.$delete(
          usePinia().$API.channels.channelMemberDetail(channelId, memberId)
        );
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in removing member from channel',
          error
        );
      }
    },
    async removeMemberFromTeam({ channelId, teamId, memberId }) {
      try {
        await usePinia().$axios.$delete(
          usePinia().$API.channels.removeMemberFromTeam(
            channelId,
            teamId,
            memberId
          )
        );
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error when removing member from team',
          error
        );
      }
    },
    async invitedMemberDetail({ channelId, invitedMemberEmail }) {
      try {
        const resp = await this.$axios.$get(
          usePinia().$API.channels.invitedChannelMemberDetail(
            channelId,
            invitedMemberEmail
          )
        );
        const key = (channelId += `>${invitedMemberEmail}`);
        this.invitedMemberDetail = {
          ...this.invitedMemberDetail,
          [key]: resp,
        };
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in invited Member Detail',
          error
        );
      }
    },
    async updateMemberRoles({ channelId, memberId, roleId }) {
      try {
        const query = '?' + roleId.map((id) => `roleId=${id}&`);
        await usePinia().$axios.$put(
          usePinia().$API.channels.channelMemberRoles(
            channelId,
            memberId,
            query.replace(/,/g, '').slice(0, -1)
          )
        );
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in update member roles',
          error
        );
      }
    },
    async getMemberRoles({ channelId, memberId }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.getChannelMemberRoles(channelId, memberId)
        );
        this.memberRoles = resp;
      } catch (error) {
        handleErrorAndNotificationHandler('Error fetching member roles', error);
      }
    },
    async fetchChannelRoles(channelId) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.getChannelRoles(channelId)
        );
        this.channelRoles = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in fetching channel roles',
          error
        );
      }
    },
    async channelMemberRoles({ channelId, memberId }) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.channels.getChannelMemberRoles(channelId, memberId)
        );
        this.memberRoles = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in fetching channel member roles',
          error
        );
      }
    },

    async updateChannel(activeChannel) {
      const channelId = activeChannel.channelId;
      const formData = new FormData();
      const pinia = usePinia();

      const stringify = JSON.stringify({
        title: activeChannel.briefTitle,
        name: activeChannel.name,
        bannerColorCSS: activeChannel.channelBanner,
      });
      formData.append('channelMetadataRequest', stringify);
      if (activeChannel.logoUrl) {
        const blobLogo = activeChannel.logoUrl;
        formData.append('logoFile', blobLogo);
      }

      if (activeChannel.bannerFile) {
        const blobBanner = activeChannel.bannerFile;
        formData.append('bannerFile', blobBanner);
      }
      try {
        await pinia.$axios.$patch(
          pinia.$API.channels.updateChannel(channelId),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } catch (error) {
        handleErrorAndNotificationHandler('Error in updating channel', error);
      }
    },
    async getUserChannels() {
      const pinia = usePinia();
      try {
        const resp = await pinia.$axios.$get(
          pinia.$API.user.getCollectionOfChannels()
        );
        this.channels = resp?.items;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in fetching user channels',
          error
        );
      }
    },
  },

  getters: {
    getActiveChannelGetter: (state) => state.activeChannel,
    getChannelTeamsGetter: (state) => state.channelTeams,
    getMemberDetailGetter: (state) => state.memberDetail,
    getChannelsGetter: (state) => {
      return state.channels;
    },
  },
});
