import { default as _500m2LpQ5z1prMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/500.vue?macro=true";
import { default as channel_45adminQnCX12mRYoMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/channel-admin.vue?macro=true";
import { default as _91categoryId_93gvz3tUeVFfMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId]/[categoryId].vue?macro=true";
import { default as _91taxonomyId_93iVtAwjdwm2Meta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId].vue?macro=true";
import { default as compliance_45insightsr20hOhxzrmMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/compliance-insights.vue?macro=true";
import { default as _91memberId_93JIUZNkvuOUMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/members/[memberId].vue?macro=true";
import { default as searcha1nVFU2EO1Meta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/search.vue?macro=true";
import { default as sites_45and_45membersHYfDXO4qRqMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/sites-and-members.vue?macro=true";
import { default as _91teamId_93K0S98a4ABeMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/teams/[teamId].vue?macro=true";
import { default as indexLKjwb1AqhKMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/timeline/index.vue?macro=true";
import { default as required_45actionsHdKRSgTR66Meta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/timeline/required-actions.vue?macro=true";
import { default as unread_45postsvafZCQjYkbMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/timeline/unread-posts.vue?macro=true";
import { default as _91channelId_93mBLI5IMbz0Meta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId].vue?macro=true";
import { default as create_45study_45siteRG0tPvfskeMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/directory/[channelId]/create-study-site.vue?macro=true";
import { default as create_45teamOh0I9JeUwKMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/directory/[channelId]/create-team.vue?macro=true";
import { default as invite_45memberQQSg92K0g0Meta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/directory/[channelId]/invite-member.vue?macro=true";
import { default as post_45creationh8iCi3mQejMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channel/post/[channelId]/post-creation.vue?macro=true";
import { default as required_45actionsTJ8zolQHM0Meta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channels/required-actions.vue?macro=true";
import { default as search3nO20rHpfXMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channels/search.vue?macro=true";
import { default as unread_45posts6D2BFrRuvHMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/channels/unread-posts.vue?macro=true";
import { default as indexYA1t5HlU4TMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/equipment/index.vue?macro=true";
import { default as indexB89k0f26zZMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/index.vue?macro=true";
import { default as detailF2p0WJwZHYMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/post/[postId]/[version]/detail.vue?macro=true";
import { default as indexOyqkbMfbQGMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/settings/[clientId]/index.vue?macro=true";
import { default as signin73a5zWuTh7Meta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/signin.vue?macro=true";
import { default as indexXMUXyxWpblMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/teams/index.vue?macro=true";
import { default as terms_45of_45servicepM4bfhgEMfMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/terms-of-service.vue?macro=true";
import { default as update_45profileRqWhlfu4FZMeta } from "/codebuild/output/src2687641029/src/eec-webapp/pages/update-profile.vue?macro=true";
export default [
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId",
    path: "/channel/:channelId()",
    meta: _91channelId_93mBLI5IMbz0Meta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId].vue").then(m => m.default || m),
    children: [
  {
    name: "channel-channelId-channel-admin",
    path: "channel-admin",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/channel-admin.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-classifications-taxonomyId",
    path: "classifications/:taxonomyId()",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId].vue").then(m => m.default || m),
    children: [
  {
    name: "channel-channelId-classifications-taxonomyId-categoryId",
    path: ":categoryId()",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/classifications/[taxonomyId]/[categoryId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "channel-channelId-compliance-insights",
    path: "compliance-insights",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/compliance-insights.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-members-memberId",
    path: "members/:memberId()",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/members/[memberId].vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-search",
    path: "search",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/search.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-sites-and-members",
    path: "sites-and-members",
    meta: sites_45and_45membersHYfDXO4qRqMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/sites-and-members.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-teams-teamId",
    path: "teams/:teamId()",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/teams/[teamId].vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline",
    path: "timeline",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/timeline/index.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline-required-actions",
    path: "timeline/required-actions",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/timeline/required-actions.vue").then(m => m.default || m)
  },
  {
    name: "channel-channelId-timeline-unread-posts",
    path: "timeline/unread-posts",
    meta: unread_45postsvafZCQjYkbMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/[channelId]/timeline/unread-posts.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "channel-directory-channelId-create-study-site",
    path: "/channel/directory/:channelId()/create-study-site",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/directory/[channelId]/create-study-site.vue").then(m => m.default || m)
  },
  {
    name: "channel-directory-channelId-create-team",
    path: "/channel/directory/:channelId()/create-team",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/directory/[channelId]/create-team.vue").then(m => m.default || m)
  },
  {
    name: "channel-directory-channelId-invite-member",
    path: "/channel/directory/:channelId()/invite-member",
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/directory/[channelId]/invite-member.vue").then(m => m.default || m)
  },
  {
    name: "channel-post-channelId-post-creation",
    path: "/channel/post/:channelId()/post-creation",
    meta: post_45creationh8iCi3mQejMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channel/post/[channelId]/post-creation.vue").then(m => m.default || m)
  },
  {
    name: "channels-required-actions",
    path: "/channels/required-actions",
    meta: required_45actionsTJ8zolQHM0Meta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channels/required-actions.vue").then(m => m.default || m)
  },
  {
    name: "channels-search",
    path: "/channels/search",
    meta: search3nO20rHpfXMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channels/search.vue").then(m => m.default || m)
  },
  {
    name: "channels-unread-posts",
    path: "/channels/unread-posts",
    meta: unread_45posts6D2BFrRuvHMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/channels/unread-posts.vue").then(m => m.default || m)
  },
  {
    name: "equipment",
    path: "/equipment",
    meta: indexYA1t5HlU4TMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/equipment/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexB89k0f26zZMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "post-postId-version-detail",
    path: "/post/:postId()/:version()/detail",
    meta: detailF2p0WJwZHYMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/post/[postId]/[version]/detail.vue").then(m => m.default || m)
  },
  {
    name: "settings-clientId",
    path: "/settings/:clientId()",
    meta: indexOyqkbMfbQGMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/settings/[clientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signin73a5zWuTh7Meta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "teams",
    path: "/teams",
    meta: indexXMUXyxWpblMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    meta: terms_45of_45servicepM4bfhgEMfMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "update-profile",
    path: "/update-profile",
    meta: update_45profileRqWhlfu4FZMeta || {},
    component: () => import("/codebuild/output/src2687641029/src/eec-webapp/pages/update-profile.vue").then(m => m.default || m)
  }
]