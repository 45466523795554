import { defineStore } from 'pinia';
import { usePinia } from '#imports';
import { handleErrorAndNotificationHandler } from '~/util/errorNotificationHandler';

export const useClientStore = defineStore('client', {
  state: () => ({
    client: {},
    favoriteChannels: [],
    channelsOwnedByClients: {
      items: [],
    },
    clientAdminContacts: [],
    membersActions: {},
    channelsStudyTeams: [],
    memberInvitations: {},
    emailEvents: {},
    clientOrganizations: [],
    clientChannelHashMap: {},
  }),
  actions: {
    async getSingleClient(clientId) {
      try {
        const pinia = usePinia();
        const resp = await pinia.$axios.$get(
          pinia.$API.clients.getClient(clientId)
        );
        this.client = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error getting single client:',
          error
        );
      }
    },
    async createNewChannel({ client, params }) {
      try {
        await client.createChannel(params);
      } catch (error) {
        handleErrorAndNotificationHandler('Error creating new channel:', error);
      }
    },
    async addFavoriteChannel(channelId) {
      try {
        await usePinia().$axios.$post(
          usePinia().$API.user.favoriteChannelsActions(channelId)
        );
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error adding channel to favorites:',
          error
        );
      }
    },
    async removeFavoriteChannel(channelId) {
      try {
        await usePinia().$axios.$delete(
          usePinia().$API.user.favoriteChannelsActions(channelId)
        );
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error removing channel from favorites:',
          error
        );
      }
    },
    async getClientOwnedChannels({ clientId, page, pageSize }) {
      const Query = usePinia().$Query;
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.clients.getChannelsOwnedByClient(
            clientId,
            new Query({
              page,
              pageSize,
            }).getQueryParameters()
          )
        );
        this.channelsOwnedByClients = resp;
        this.clientChannelHashMap = {
          ...this.clientChannelHashMap,
          [clientId]: resp.items,
        };
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching client owned channels:',
          error
        );
      }
    },
    async getAdminContactsForClient(clientId) {
      try {
        const resp = await usePinia().$axios.$get(
          usePinia().$API.clients.getClientAdminContacts(clientId)
        );
        this.clientAdminContacts = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching admin contacts for a client:',
          error
        );
      }
    },
    async addChannelAdminToTeam({ channelId, clientId, teamId }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        await pinia.$axios.$post(
          pinia.$API.clients.postChannelAdmin(
            new Query({ clientId, channelId, teamId }).getQueryParameters()
          )
        );
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error adding channel admin to team:',
          error
        );
      }
    },
    async getMembersActions({ search, sort, actionType }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$get(
          pinia.$API.user.getMembershipActions(
            new Query({ search, sort, actionType }).getQueryParameters()
          )
        );
        this.membersActions = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching members actions:',
          error
        );
      }
    },
    async acceptDeclineInvitation({ allInvites, params }) {
      const resp = await allInvites.acceptDecline(params);
      this.invitationStatus = resp;
    },
    async getClientStudyTeamForChannels({ clientId, typeOfTeam, channelId }) {
      try {
        const query = '?' + typeOfTeam.map((t) => `teamType=${t}&`);
        const channel = `channelId=${channelId}`;
        const teamType =
          query.replace(/,/g, '') + `teamStatusType=${'OPEN'}&` + channel;
        const resp = await usePinia().$axios.$post(
          usePinia().$API.clients.clientStudyTeamsForChannels(
            clientId,
            teamType
          ),
          []
        );
        resp.items.sort((a, b) => {
          let compare = 0;
          if (a.studyTeam.teamType < b.studyTeam.teamType) {
            compare = -1;
          }
          if (a.studyTeam.teamType > b.studyTeam.teamType) {
            compare = 1;
          }
          return compare;
        });
        this.channelsStudyTeams = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching Client Study Team For Channels:',
          error
        );
      }
    },
    async fetchMemberInvitations({
      teamId,
      userId,
      page,
      pageSize,
      search,
      sort,
    }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$post(
          pinia.$API.comments.searchChannelInvitation(
            new Query({
              teamId,
              userId,
              sort,
              search,
              page,
              pageSize,
            }).getQueryParameters()
          ),
          []
        );
        this.memberInvitations = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching member invitations:',
          error
        );
      }
    },
    async latestEmailEvent({ channelId, memberId, emailId, page, pageSize }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$get(
          pinia.$API.channels.statusEmailForMember(
            channelId,
            memberId,
            new Query({ emailId, page, pageSize }).getQueryParameters()
          )
        );
        const key = emailId;
        this.emailEvents = {
          ...this.emailEvents,
          [key]: resp,
        };
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error fetching latest email event:',
          error
        );
      }
    },
    async searchClientOrganizations({ clientId, sponsorName }) {
      try {
        const pinia = usePinia();
        const Query = pinia.$Query;
        const resp = await pinia.$axios.$post(
          pinia.$API.clients.searchClientOrganizations(
            clientId,
            new Query({ keywords: sponsorName }).getQueryParameters()
          )
        );
        this.clientOrganizations = resp;
      } catch (error) {
        handleErrorAndNotificationHandler(
          'Error in search client Organizations:',
          error
        );
      }
    },
    removeInvite(payload) {
      this.membersActions.invites.items =
        this.membersActions.invites.items.filter((i) => i.inviteId !== payload);
    },
  },

  getters: {
    getTeamsInChannelGetter: (state) => state.channelsStudyTeams,
    getMembersActionsGetter: (state) => state.membersActions,
  },
});
