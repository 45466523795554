import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2687641029/src/eec-webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/codebuild/output/src2687641029/src/eec-webapp/.nuxt/sentry-client-config.mjs";
import plugin_AOuQ1DYzjk from "/codebuild/output/src2687641029/src/eec-webapp/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import piniaPlugin_2d7eRqwsc8 from "/codebuild/output/src2687641029/src/eec-webapp/plugins/piniaPlugin.ts";
import error_handler_PlzlZhyP5t from "/codebuild/output/src2687641029/src/eec-webapp/plugins/error.handler.ts";
import api_GFfDXud5Cr from "/codebuild/output/src2687641029/src/eec-webapp/plugins/api.ts";
import auth_vT9JWWT9pN from "/codebuild/output/src2687641029/src/eec-webapp/plugins/auth.ts";
import axios_QMFgzss1s4 from "/codebuild/output/src2687641029/src/eec-webapp/plugins/axios.ts";
import croppie_client_vPejxUSMYa from "/codebuild/output/src2687641029/src/eec-webapp/plugins/croppie.client.ts";
import emitter_eaMgM0oZTb from "/codebuild/output/src2687641029/src/eec-webapp/plugins/emitter.ts";
import lodash_Tzg4BPll2N from "/codebuild/output/src2687641029/src/eec-webapp/plugins/lodash.ts";
import luxon_5MWj2Lijxd from "/codebuild/output/src2687641029/src/eec-webapp/plugins/luxon.ts";
import models_FdDdhdvewc from "/codebuild/output/src2687641029/src/eec-webapp/plugins/models.ts";
import sockets_client_fo7Dm8qmmF from "/codebuild/output/src2687641029/src/eec-webapp/plugins/sockets.client.ts";
import utility_client_i1IblItTjt from "/codebuild/output/src2687641029/src/eec-webapp/plugins/utility.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_AOuQ1DYzjk,
  piniaPlugin_2d7eRqwsc8,
  error_handler_PlzlZhyP5t,
  api_GFfDXud5Cr,
  auth_vT9JWWT9pN,
  axios_QMFgzss1s4,
  croppie_client_vPejxUSMYa,
  emitter_eaMgM0oZTb,
  lodash_Tzg4BPll2N,
  luxon_5MWj2Lijxd,
  models_FdDdhdvewc,
  sockets_client_fo7Dm8qmmF,
  utility_client_i1IblItTjt
]