import { useNuxtApp } from '#app';

export function handleErrorAndNotificationHandler(customMessage, error) {
  const emitter = useNuxtApp().$emitter;
  console.error(customMessage, error);
  const statusData = {
    message: error?.response?.data?.errorDescription || 'An error occurred',
    status: error?.response?.status,
  };
  emitter.$emit('toast-global-message', statusData);
}
