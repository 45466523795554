import { useHelperStore } from '~/stores/helpers';
import { DEBUG, GLOBAL_MESSAGE_EVENT, type PayloadDataType } from '.';
import { useChannelsStore } from '~/stores/channels';

type createChannel = {
  channelId: number;
  eventType: 'CREATED';
  userName: string;
  userId: number;
};

/**
 * Handles the CREATED pubsub event.
 *
 * The key is misleading to it's value a bit, the actual message from the
 * pusher is "create-draft-post".
 *
 * SIDE EFFECTS: Fetches the post summary and emits a global message.
 *
 * @param {PayloadDataType} PayloadDataType - The channel and data
 *
 * @returns {Promise<void>}
 */
export async function handleCreateChannel(
  ...[_channel, data]: PayloadDataType<createChannel>
) {
  DEBUG && console.log('handlePusherMessages', data);
  const piniaHelper = useHelperStore();
  const { status } = piniaHelper.latestResponseStatus as { status: number };
  const emitter = useNuxtApp().$emitter

  emitter.$emit(GLOBAL_MESSAGE_EVENT, {
    status,
    eventType: data.eventType,
  });

  if (status >= 200 && status < 300) {
    const piniaChannels = useChannelsStore();
    await piniaChannels.getUserChannels();
    emitter.$emit('channel-created');
  }
}
